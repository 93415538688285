html {
  font-size: 16px;
}

h1 {
}

h2 {
  font-size: 2rem;
  letter-spacing: 4px;
}

.app {
  padding: 2rem;
  background: black url("/BTV_ONE_bg.jpg") right top no-repeat;
  text-align: center;
  color: white;
  font-family: "Inconsolata", monospace;
  font-weight: 600;
  letter-spacing: 1px;
}

a {
  color: white;
}

a:hover {
  color: cyan;
}

.appHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btvIcon {
  width: 600px;
  max-width: 90%;
}

/* VIDEOS */
.videos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  .video {
    margin: 0.5rem 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 3rem;

    .video {
      width: 33.333%;
      margin: 1rem;
    }
  }
}

.releaseInfo {
  margin: 0 auto;
  padding: 1rem 1rem 2rem 1rem;
}

.release {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 2rem;
  }
}

.cover a img {
  border: 1px solid #cccccc;
}

.listen {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100%;
    margin: 2rem 0 0 0;
    padding: 0;
    @media (min-width: 768px) {
      margin: 0;
      padding-left: 2rem;
    }
  }
  li {
    list-style-type: none;
    list-style-image: none;
    margin-bottom: 1rem;
  }
}

.socialLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.socialLinks li {
  margin: 1rem;
  text-align: left;
}

/* GLOBAL */
.srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
